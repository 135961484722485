import Analytics from 'analytics';
/** @ts-ignore */
import gtag from './gtag';
/** @ts-ignore */
import fbPixel from './fb-pixel';
/** @ts-ignore */
import ttPixel from './tt-pixel';

const configuration = {
  app: 'exnova-off-bot',
  plugins: [
    gtag({
      containerId: 'REACT_APP_GOOGLE_TOKEN',
    }),
    fbPixel({
      trackingId: 'REACT_APP_FB_PIXEL_ID',
    }),
    ttPixel({
      trackingId: 'REACT_APP_TT_PIXEL_ID',
    })
  ]
};

class BotAnalytics {
  analytics: any = null;

  configuration = {};

  /** @ts-ignore */
  constructor(config) {
    this.configuration = config;
  }

  init() {
    /** @ts-ignore */
    this.analytics = new Analytics(this.configuration);
  }

  track(...args: any[]) {
    if (!this.analytics) {
      throw new Error('Analytics is not initialized');
    }
    this.analytics.track(...args);
  }

  page(...args: any[]) {
    if (!this.analytics) {
      throw new Error('Analytics is not initialized');
    }
    this.analytics.page(...args);
  }

  identify(...args: any[]) {
    if (!this.analytics) {
      throw new Error('Analytics is not initialized');
    }
    this.analytics.identify(...args);
  }
}

export default new BotAnalytics(configuration);
